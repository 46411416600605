import React, { useCallback, useContext, useEffect } from 'react';
import { Card } from 'ni-ui/layout';
import { shape } from 'prop-types';
import styles from './styles.scss';
import { messageReceiver } from './Helpers';
import { AppContext } from '../../app-context';
import { TAFAuthentications } from '../../api';
import Fallback from '../fallback';


const TAF = ({ TAFData }) => {
  const { 
    setContextState,
    outletRef,
    orderRef,
    orderDetails
   } = useContext(AppContext);
  const {
    uri
  } = TAFData.authenticationMethod.uriData;
  const authenticateUiURL = `${uri}?origin=${window.location.origin}`
  const urlData = new URL(authenticateUiURL);
 
  const handleTAFFlow = useCallback( async(TAFResponse) => {
    try {
      const { data } = await TAFAuthentications(outletRef, orderRef, orderDetails.order.paymentReference, TAFResponse);
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: data.state,
        }
      });
    } catch (error) {
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: 'FAILED',
        }
      });
    }
  }, []) 
  
  
  // Center the pop-up on the screen
  const width = 550;
  const height = 700;
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;
  
  const windowRef =  window.open(
    authenticateUiURL,
    'AuthenticatorWindow',
    `width=${width},height=${height},top=${top},left=${left},resizable=no,scrollbars=no`
  ) 

  useEffect(() => {
    window.addEventListener('message', event => messageReceiver(event, windowRef, urlData, TAFData, handleTAFFlow));
    // Clean up the event listener
    return () => {
      window.removeEventListener('message', event => messageReceiver(event, windowRef, urlData, TAFData, handleTAFFlow));
    };
  }, []);

  return (
    <Card inheritBackgroundColor>
      <div data-testid="TAF_message" className={`${styles.TAF} ${styles.inheritWidth}`}>
        <Fallback message="CUSTOM_PENDING_PAYMENT_MESSAGE"/>
      </div>
    </Card>
  );
};

TAF.propTypes = {
  TAFData: shape({}),
};

TAF.defaultProps = {
  TAFData: null,
};

export default TAF;
