import { some, get } from 'lodash';
import { style } from 'ni-ui/theme';

const nameRegX = /^[a-z\s]{0,50}$/i;
const ArabicNameRegX = /^[\u0600-\u06FF\u0750-\u077F]{0,50}$/i;
const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const mobileNumberRegex = /^\d{9,13}$/;
// For Emirates [AANI Validation]
const uaeMobileNoRegex = /^\d{5,13}$/;
const emiratesIdRegex = /^784-[0-9]{4}-[0-9]{7}-[0-9]$/;
const passportIdRegex = /^[0-9CFGHJKLMNPRTVWXYZ]{9}$/;
const aaniEmailRegex = /^[a-zA-Z][a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const minorToMajorUnits = (value, minorUnit) => (
  parseFloat((value / (10 ** minorUnit))).toFixed(minorUnit)
);

export const getMinorUnitBy = (currencyCode) => {
  // eslint-disable-next-line no-undef
  const numberFormatter = Intl.NumberFormat('en', {
    style: 'currency',
    currency: currencyCode || 'AED'
  });

  const { minimumFractionDigits } = numberFormatter.resolvedOptions();
  return minimumFractionDigits;
};

const getMinorUnit = (minorUnit) => {
  if (minorUnit || minorUnit === 0) {
    return minorUnit;
  }
  // default
  return 2;
};

export const amountToMinor = (value, minorUnit) => parseFloat((value *
  (10 ** getMinorUnit(minorUnit))).toFixed(getMinorUnit(minorUnit)));

export const minorToMajorUnitsFromCurrencyCode = (value, currencyCode) => {
  const minimumFractionDigits = getMinorUnitBy(currencyCode);
  return minorToMajorUnits(value, minimumFractionDigits);
};

export const isKsaRegion = () =>
  ['ksa'].some(part => window.location.hostname.includes(part));

export const env = {
  DEV: 'DEV',
  LOCAL: 'LOCAL',
  PROD: 'PROD',
  UAT: 'UAT'
};
export const getEnvironment = () => {
  if (
    some(['localhost'], el => window.location.hostname.indexOf(el) >= 0) ||
    window.location.protocol === 'http:'
  ) {
    return env.LOCAL;
  } else if (some(['dev'], el => window.location.hostname.indexOf(el) >= 0)) {
    return env.DEV;
  } else if (
    some(['uat', 'sandbox'], el => window.location.hostname.indexOf(el) >= 0)
  ) {
    return env.UAT;
  }
  return env.PROD;
};

export function getTotalAmount(order, total, isRecurringEnabled, mcpEnabled, currentSelectedPaymentMethod) {
  if (mcpEnabled && currentSelectedPaymentMethod === 'APPLE_PAY') {
    return `${get(order, 'originalAmount.currencyCode', 'AED')} ${get(order, 'originalAmount.formattedValue', '0.00')}`;
  }
  if (isRecurringEnabled || order.type === 'INSTALLMENT') {
    return `${order.amount.currencyCode} ${order.amount.formattedValue}`;
  }
  return total ? `${total.currencyCode} ${total.formattedValue}` : 0;
}

export const nameValidation =
  value => (value && (nameRegX.test(value) || ArabicNameRegX.test(value)));
export const emailValidation = value => (value && emailRegex.test(value));
export const mobileNumberValidation = value => (value && mobileNumberRegex.test(value));
export const uaeMobileNoRegexValidation = value => (value && uaeMobileNoRegex.test(value));
export const emiratesIdRegexValidation = value => (value && emiratesIdRegex.test(value));
export const passportIdRegexValidation = value => (value && passportIdRegex.test(value));
export const aaniEmailRegexValidation = value => (value && aaniEmailRegex.test(value));


export const nonMcpSupportedPaymentMethods = ['AANI', 'APPLE_PAY', 'SAMSUNG_PAY', 'GOOGLE_PAY'];

export const formatOrder = (orderDetails, currentSelectedPaymentMethod) => {
  const { order, showRecurringSection, mcpEnabled } = orderDetails;
  if (order.orderSummary) {
    const {
      items: originalItems = [],
      shipping,
      tax,
      total
    } = order.orderSummary;
    const items = originalItems.map(({ description, quantity, totalPrice }) => ({
      key: `${description}.${quantity}.${totalPrice.value}`,
      description,
      quantity,
      price: `${totalPrice.currencyCode} ${totalPrice.formattedValue}`
    }));
    return {
      items,
      shippingAmt: shipping ? `${get(shipping, 'currencyCode', total.currencyCode)} ${get(shipping, 'formattedValue', 0)}` : 0,
      taxAmt: tax ? `${get(tax, 'currencyCode', total.currencyCode)} ${get(tax, 'formattedValue', 0)}` : 0,
      totalAmt: getTotalAmount(order, total, showRecurringSection, mcpEnabled, currentSelectedPaymentMethod),
    };
  }
  // Exception:  use the original order amount and currency
  if (mcpEnabled && currentSelectedPaymentMethod === 'APPLE_PAY') {
    return {
      items: [],
      totalAmt: `${get(order, 'originalAmount.currencyCode', 'AED')} ${get(order, 'originalAmount.formattedValue', '0.00')}`,
      taxAmt: 0,
      shippingAmt: 0,
    };
  }
  return {
    items: [],
    totalAmt: `${order.amount.currencyCode} ${order.amount.formattedValue}`,
    taxAmt: 0,
    shippingAmt: 0,
  };
};

export const applyCustomTextColors = (textColor) => style`
label span {
  color: ${textColor} !important;
}
span:not(.ni-alert span):not(label span) {
  color: ${textColor} !important;
}
input {
color: ${textColor} !important;
}
h3 {
color: ${textColor} !important;
}
`('custom-text-color');

export const toBoolean = (str) => str?.toLowerCase() === 'true';
