import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { shape, string } from 'prop-types';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import { Card } from 'ni-ui/layout';
import { AppContext } from '../../app-context';
import styles from './styles.scss';
import { challengeResponse } from '../../api';

const ThreeDSChallengeFrame = ({ threeDsData }) => {
  const { base64EncodedCReq, acsURL } = threeDsData;

  const {
    outletRef, orderRef, orderDetails, refetchOrder, setContextState
  } = useContext(AppContext);
  const paymentRef = orderDetails.order.paymentReference;

  const messageReceiver = useCallback(async (message) => {
    const messageData = get(message, 'data', '');
    if (startsWith(messageData, '3DS2_CHALLENGE_COMPLETE')) {
      try {
        const base64EncodedCRes = messageData.split(':')[1];
        await challengeResponse(outletRef, orderRef, paymentRef, {
          base64EncodedCRes
        });
        refetchOrder();
      } catch (err) {
        setContextState({
          orderDetails: {
            ...orderDetails,
            state: 'FAILED',
            order: {
              ...orderDetails.order,
              state: 'FAILED',
            }
          }
        });
      }
    }
  }, [outletRef, orderRef, paymentRef]);

  const challengeFrameSubmitRef = useRef();
  useEffect(() => {
    window.addEventListener('message', messageReceiver);
    challengeFrameSubmitRef.current.click();
    return () => {
      window.removeEventListener('message', messageReceiver);
    };
  }, [messageReceiver]);

  return (
    <Card inheritBackgroundColor>
      <form action={acsURL} className={styles.hidden} method="post" target="3ds_iframe">
        <input type="hidden" name="creq" value={base64EncodedCReq} />
        <input type="submit" ref={challengeFrameSubmitRef} />
      </form>
      <iframe
        data-testid="3ds_iframe"
        name="3ds_iframe"
        id="3ds_iframe"
        title="3DS"
        className={styles.threeDSIframe}
      />
    </Card>
  );
};

ThreeDSChallengeFrame.propTypes = {
  threeDsData: shape({
    base64EncodedCReq: string,
    acsURL: string
  }).isRequired,
};

export default ThreeDSChallengeFrame;
