import React, { useContext, useCallback, useState, Fragment, useEffect } from 'react';
import toLower from 'lodash/toLower';
import toUpper from 'lodash/toUpper';
import get from 'lodash/get';
import Text, { Translate } from 'ni-ui/text';
import Input from 'ni-ui/input';
import httpClient from 'ni-ui/httpClient';
import ToolTip from 'ni-ui/tooltip';
import Info from 'ni-ui/icon/lib/Info';
import { PropTypes } from 'prop-types';
import Dialogue, { types } from 'ni-ui/dialogue';
import { IconButton } from 'ni-ui/svg-icon';
import Bin from 'ni-ui/icon/lib/Bin';
import { getCardIcon } from '../../card-icons/card-icons';
import { AppContext } from '../../../app-context';
import { PaymentMethodsContext, usePaymentMethodSetter } from '../payment-methods';
import useStateWithRef from '../../hooks/useStateWithRef';
import styles from './styles.scss';
import { deleteSavedCard } from '../../../api';

export const validate = (card, cvv) => {
  const currentCard = card;
  const showCvv = get(currentCard, 'recaptureCsc', '') !== 'false';
  if (toLower(currentCard.scheme) === 'american_express' || toLower(currentCard.scheme) === 'amex') {
    if (!/^[0-9]{4}$/.test(cvv)) {
      return ['CVV_ERROR'];
    }
  } else if (showCvv && !/^[0-9]{3}$/.test(cvv)) {
    return ['CVV_ERROR'];
  }
  return [];
};


const formatExpiry = (expiry) => {
  const parts = expiry.split('-');
  return `${parts[1]}/${parts[0].substr(-2)}`;
};

const SavedCard = ({ card, index }) => {
  const {
    orderDetails, setContextState, outletRef, orderRef, selectedInstallmentOption
  } = useContext(AppContext);

  const { name: paymentMethodName } = useContext(PaymentMethodsContext);

  const [isDeleteDialogue, setIsDeleteDialogue] = useState(false);
  const showCvv = get(card, 'recaptureCsc', '') !== 'false';

  const [cardState, setCardState, cardStateRef] = useStateWithRef({
    cvv: '',
  });

  useEffect(() => {
    setCardState({ cvv: '' });
  }, [paymentMethodName]);

  const onClickPayNow = useCallback(async () => {
    const { cardholderName, cardToken, expiry } = card;
    const { cvv } = cardStateRef.current;
    let payload = {
      cardholderName,
      cardToken,
      expiry,
      ...(selectedInstallmentOption ? {
        vis: {
          planSelectionIndicator: selectedInstallmentOption.numberOfInstallments !== 'FULL',
          vPlanId: selectedInstallmentOption.vPlanID,
          acceptedTAndCVersion: selectedInstallmentOption.termsAndConditions
        }
      } : {})
    };
    if (showCvv) {
      payload = { ...payload, cvv };
    }
    try {
      const { data } = await httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/saved-card`, payload);

      setContextState({
        orderDetails: {
          ...orderDetails,
          state: data.state,
          order: data.order,
        }
      });
    } catch (error) {
      if (error.response.data.errors[0].errorCode === 'cardNotSupportRecurringPayment') {
        throw new ReferenceError(error.response.data.errors[0].message);
      } else {
        throw new Error(error);
      }
    }
  }, [orderDetails.order, outletRef, orderRef, selectedInstallmentOption]);

  usePaymentMethodSetter({
    onClickPayNow,
    disablePayNow: validate(card, cardState.cvv).length > 0,
    id: index !== undefined ? `SAVEDCARDS${index}` : 'SAVEDCARD'
  });

  const getLoaderAndDeleteCardState = (
    deleteCardType,
    deleteCardMessage
  ) => ({
    loader: {
      state: 'DONE',
      message: 'INITIAL_LOADER_TEXT'
    },
    deleteCardResult:
      {
        type: deleteCardType,
        message: deleteCardMessage
      }
  });

  const handelDeleteSavedCard = useCallback(async () => {
    try {
      setContextState({
        loader: {
          state: 'REFETCH',
          message: 'REMOVE_CARD_LOADER_TEXT'
        }
      });
      const { data } = await deleteSavedCard(outletRef, orderRef, card.cardToken);
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: data.state,
          order: data.order,
        },
        ...getLoaderAndDeleteCardState(
          'SUCCESS',
          'SUCCESS_REMOVE_CARD_MESSAGE'
        )
      });
    } catch (error) {
      setContextState({
        ...getLoaderAndDeleteCardState(
          'ERROR',
          'ERROR_REMOVE_CARD_MESSAGE'
        )
      });
    }
  }, []);

  const cardScheme = card.scheme;
  const Icon = getCardIcon(toUpper(cardScheme));
  const showDeleteIcon = orderDetails.manageMyCards.deleteMyCardEnabled;

  return (
    <Fragment>
      <div className={styles.savedCardContainer}>
        <div className={styles.cardDetails}>
          {Icon ? <Icon size={32} data-testid={cardScheme} /> : null}
          <div className={styles.cardDataContainer}>
            <Text
              textKey="SAVED_CARD"
              values={{
                ...card,
                expiry: formatExpiry(card.expiry)
              }}
            />
          </div>
          {showCvv && (
            <div className={`${styles.cvvContainer} ${styles.securityCodeFont}`}>
              <Input
                type="password"
                labelKey="CVV"
                onChange={cvv => setCardState({ cvv })}
                value={cardState.cvv}
              />
              <div className={styles.toolTipContainer}>
                <ToolTip textKey="CVV_TOOLTIP">
                  <Info size={12} />
                </ToolTip>
              </div>
            </div>
          )}
        </div>
        {showDeleteIcon &&
          <IconButton
            data-testid="deleteIcon"
            icon={<Bin color="gray" size={20} />}
            onClick={() => setIsDeleteDialogue(true)}
          />}
      </div>
      <Dialogue
        cancelBtnTextKey="CANCEL"
        confirmBtnTextKey="CONFIRM_REMOVE"
        onConfirm={handelDeleteSavedCard}
        titleKey="CONFIRM_REMOVE_TITLE"
        open={isDeleteDialogue}
        type={types.CONFIRM}
        closeDialogue={() => setIsDeleteDialogue(false)}
        disableI18n
      >
        <div className={styles.paddingTop}>
          <Translate textKey="CONFIRM_REMOVE_CARD" />
        </div>
      </Dialogue>
    </Fragment>
  );
};

SavedCard.defaultProps = {
  index: undefined
};

SavedCard.propTypes = {
  card: PropTypes.shape({}).isRequired,
  index: PropTypes.number
};

export default SavedCard;
