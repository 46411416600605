import React, { Fragment, useContext, useState } from 'react';
import { Card, Col, Row } from 'ni-ui/layout';
import { Alert, NotificationType } from 'ni-ui/notification';
import Text, { TextTypes } from 'ni-ui/text';
import Button, { ButtonTypes } from 'ni-ui/button';
import get from 'lodash/get';
import styles from './styles.scss';
import { AppContext } from '../app-context';
import { acceptPartialAuth, declinePartialAuth } from '../api';
import Fallback from '../components/fallback';
import Error from '../components/error';
import { minorToMajorUnitsFromCurrencyCode } from '../utils';

const PartialAuthPage = () => {
  const { orderDetails, setContextState } = useContext(AppContext);
  const { order } = orderDetails;
  const { _embedded: { payment } } = order;
  const issuerOrg = get(payment[0], 'paymentMethod.issuingOrg', '');
  const currency = get(payment[0], 'amount.currencyCode', 'AED');
  const amount = minorToMajorUnitsFromCurrencyCode(get(payment[0], 'originalAmount.value', '0'), currency);
  const partialAmount = minorToMajorUnitsFromCurrencyCode(get(payment[0], 'amount.value', '0'), currency);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleClick = async (apiFn) => {
    try {
      setLoading(true);
      const resp = await apiFn(order.outletId, order.reference, payment[0].reference);
      const orderResp = get(resp, 'data.order');
      setLoading(false);
      setError('');
      setContextState({
        orderDetails: {
          ...orderDetails,
          ...orderResp,
        }
      });
    } catch (err) {
      setLoading(false);
      setError('error');
    }
  };
  if (loading) {
    return (
      <Fallback />
    );
  }

  if (error) {
    return (
      <Error messageKey="ERROR" />
    );
  }

  return (
    <Fragment>
      <Card inheritBackgroundColor>
        <div className={styles.partialAuthHeader}>
          <Row>
            <Col>
              {
                issuerOrg ? (<Alert
                  type={NotificationType.WARNING}
                  textKey="PARTIAL_AUTH_MESSAGE_WITH_ORG"
                  values={{
                     issuerOrg,
                     amount,
                     partialAmount,
                     currency
                  }}
                  align="center"
                />
                ) : (<Alert
                  type={NotificationType.WARNING}
                  textKey="PARTIAL_AUTH_MESSAGE"
                  values={{ amount, partialAmount, currency }}
                  align="center"
                />
                )
              }
            </Col>
          </Row>
        </div>
        <div className={styles.partialAuthConfirmationContainer}>
          <div className={styles.confirmationTextContainer}>
            <div className={styles.confirmationText}>
              <Text textKey="PARTIAL_AUTH_MESSAGE_QUESTION" type={TextTypes.BODY} />
            </div>
          </div>
          <div className={styles.yesNoButtonContainer}>
            <div className={styles.yesNoButton}>
              <Button
                type={ButtonTypes.PRIMARY}
                onClick={(async () => {
                  await handleClick(acceptPartialAuth);
                })}
                textKey="YES"
                disabled={loading}
              />
            </div>
            <div className={styles.yesNoButton}>
              <Button
                type={ButtonTypes.SECONDARY}
                onClick={(async () => {
                  await handleClick(declinePartialAuth);
                })}
                textKey="NO"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </Card>
    </Fragment>
  );
};

export default PartialAuthPage;
