import React, { useContext } from 'react';
import { string, bool, oneOfType } from 'prop-types';
import Text from 'ni-ui/text';
import styles from './styles.scss';
import Spinner from '../spinner';
import { AppContext } from '../../app-context';

const Fallback = ({ message }) => {
  const { isDarkTheme } = useContext(AppContext);
  return (
    <div className={`${styles.fallbackContainer} ${isDarkTheme ? styles.darkBackground : ''} appRoot`}>
      <Spinner />
      {message &&
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Text textKey={message} />
        </div>}
    </div>
  );
}

Fallback.propTypes = {
  message: oneOfType([bool, string]),
};

Fallback.defaultProps = {
  message: '',
};

export default Fallback;
