import get from 'lodash/get';

export const isApplePayAvailable = () =>
  window.ApplePaySession && window.ApplePaySession.supportsVersion;


const isPaymentMethodEnabled = (paymentMethod, type) => (orderDetails) => {
  const orderPaymentMethods = get(orderDetails.paymentMethods, type, []);
  return orderPaymentMethods
    .filter(orderPaymentMethod => orderPaymentMethod === paymentMethod).length > 0;
};

const isMadaEnabled = isPaymentMethodEnabled('MADA', 'card');

export const getPaymentRequest = (orderDetails) => {
  const {
    order: {
      reference,
      amount,
      originalAmount
    },
    mcpEnabled
  } = orderDetails;
  const supportedNetworks = [
    'visa',
    'masterCard',
    'amex',
    'discover'
  ];
  let countryCode = 'AE';

  if (isMadaEnabled(orderDetails)) {
    supportedNetworks.push('mada');
    countryCode = 'SA';
  }
  const selectedAmount = mcpEnabled ? originalAmount : amount;
  const selectedCurrencyCode = selectedAmount?.currencyCode ?? 'AED';
  const selectedFormattedValue = selectedAmount?.formattedValue ?? '0.00';

  return {
    countryCode,
    currencyCode: selectedCurrencyCode,
    merchantCapabilities: [
      'supports3DS'
    ],
    supportedNetworks,
    total: {
      label: `Outlet for order: ${reference}`,
      type: 'final',
      amount: selectedFormattedValue
    }
  };
};
