import React, { useContext } from 'react';
import { ThemeContext } from 'ni-ui/theme';
import Text, { TextTypes } from 'ni-ui/text';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.scss';

import { AppContext } from '../../app-context';
import PropTypes from 'prop-types';

const BrandingFooter = ({ isPaymentPage }) => {
  const { orderDetails, slim } = useContext(AppContext);
  const skipConfirmationPage = get(orderDetails, 'order.merchantAttributes.skipConfirmationPage', 'false');
  const theme = useContext(ThemeContext);
  const brandFooterPresent = !!theme.footerImageUrl || !!theme.footerText;
  const shouldHide = isPaymentPage ? (slim || (skipConfirmationPage === 'true' &&
    (orderDetails.state === 'CAPTURED' ||
      orderDetails.state === 'FAILED' ||
      orderDetails.state === 'AUTHORISED' ||
      orderDetails.state === 'VERIFIED' ||
      orderDetails.state === 'CANCELLED'))) : false;
  if (shouldHide) {
    return null;
  }

  return (
    <>
      {brandFooterPresent &&
        <>
          <div className={styles.imageContainer}>
            {!isEmpty(theme.footerImageUrl) &&
            <img className={styles.image} src={theme.footerImageUrl} alt='footerImage' />
            }
          </div>
          <div className={styles.text}>
            {!isEmpty(theme.footerText) &&
              <Text type={TextTypes.H4} disableI18n textKey={theme.footerText} />
            }
          </div>
        </>
      }
    </>
  )

}

BrandingFooter.propTypes = {
  isPaymentPage: PropTypes.bool
}

export default BrandingFooter;
