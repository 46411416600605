export const AuthenticateAction = {
    Ready: 'Ready',
    Success: 'Success',
    Authenticate: 'Authenticate',
    Error: 'Error'
  }

export const messageReceiver = (message, windowRef, urlData, TAFData, handleTAFFlow) => {
    const { data } = message
    
    switch (data.action) {
      case AuthenticateAction.Ready:
        windowRef.postMessage({
          action: AuthenticateAction.Authenticate,
          payload: TAFData
        }, urlData.origin)
        return 'Authenticate action sent' 
      case AuthenticateAction.Success:
        handleTAFFlow(data.payload)
        windowRef.close()
        return 'Success' 
      case AuthenticateAction.Error:
        handleTAFFlow({authenticationError : data.payload})
        windowRef.close()
        return 'Error'
    }
  };