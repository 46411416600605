import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'ni-ui/layout';
import colors from 'ni-ui/colors';
import { Alert, NotificationType } from 'ni-ui/notification';
import Input from 'ni-ui/input';
import Select from 'ni-ui/select';
import AmountInput from 'ni-ui/amount-input';
import { toUpper } from 'lodash';
import Button, { ButtonTypes } from 'ni-ui/button';
import Text, { TextTypes } from 'ni-ui/text';
import styles from './styles.scss';
import { countryCodes } from '../../constants/country-code';
import { getMinorUnitBy, minorToMajorUnitsFromCurrencyCode } from '../../utils';
import { validationErrorMap, InvalidErrorMessages, allowPaySubmission, handleCreateOrder } from './handlers';

const InputFields = (props) => {
  const {
    minAmount,
    maxAmount,
    description,
    editable,
    invoiceRef,
    currencyCode,
    setPayNowStatus,
    payNowStatus
  } = props;
  const [validationError, setValidationError] = useState({ isValidationError: false, message: '' });
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    countryCode: '',
    mobileNumber: '',
    email: '',
    defaultAmount: props.amount.value / (10 ** getMinorUnitBy(currencyCode)),
    amount: {
      value: props.amount.value / (10 ** getMinorUnitBy(currencyCode)),
      currencyCode,
    }
  });
  const isZeroMinMaxAmount = minAmount.value === 0 && maxAmount.value === 0;
  const isValidValue = (newValue, inputName) => {
    if (!validationErrorMap[inputName](newValue, minAmount.value, maxAmount.value, editable)) {
      setValidationError({ isValidationError: true, message: InvalidErrorMessages[inputName] });
    } else {
      setValidationError({ isValidationError: false, message: '' });
    }
  };

  const handleChange = (newValue, inputName) => {
    setUserData({ ...userData, [inputName]: newValue });
  };

  return (
    <div className={styles.inheritWidth}>
      <Row gutter={false}>
        <Col span={12} >
          <div style={{ padding: '30px 0px 10px' }}>
            <Text
              textKey="DESCRIPTION_MESSAGE"
              type={TextTypes.H3}
              values={{ description }}
            />
          </div>
        </Col>
        <Col span={12} >
          <div>
            <Text
              textKey="ASTERISK_FIELD_REQUIRED"
              type={TextTypes.BODY}
              textColor={colors.RED_ERROR}
            />
          </div>
        </Col>
      </Row>
      {validationError.isValidationError ?
        <Row>
          <Col>
            <Alert type={NotificationType.ERROR} textKey={validationError.message} />
          </Col>
        </Row> : null}
      <Row gutter={false}>
        <Col span={6} sm={12}>
          <Input
            onChange={value => handleChange(value, 'firstName')}
            labelKey="USER_FIRST_NAME_ASTERISK"
            value={userData.firstName}
            onBlur={value => isValidValue(value, 'firstName')}
          />
        </Col>
        <Col span={6} sm={12}>
          <Input
            onChange={value => handleChange(value, 'lastName')}
            labelKey="USER_LAST_NAME_ASTERISK"
            value={userData.lastName}
            onBlur={value => isValidValue(value, 'lastName')}
          />
        </Col>
        <Col span={6} sm={12}>
          <Select
            onSelect={(value) => {
              handleChange(value.countryCode.split(' ').slice(0, 1).join(''), 'countryCode');
              }}
            options={countryCodes}
            title="USER_COUNTRY_CODE_ASTERISK"
            titleTextColor={colors.GREY_MEDIUM}
            id="countryCodeSelect"
            showClearButton={false}
            searchFilter={
              (option, searchValue) =>
                toUpper(option.text).startsWith(toUpper(searchValue)) ||
                toUpper(option.search).startsWith(toUpper(searchValue))
            }
          />
        </Col>
        <Col span={6} sm={12}>
          <Input
            onChange={value => handleChange(value, 'mobileNumber')}
            labelKey="USER_MOBILE_NUMBER_ASTERISK"
            value={userData.mobileNumber}
            onBlur={value => isValidValue(value, 'mobileNumber')}
          />
        </Col>
        <Col span={6} sm={12}>
          <Input
            onChange={value => handleChange(value, 'email')}
            labelKey="USER_EMAIL_ASTERISK"
            value={userData.email}
            onBlur={value => isValidValue(value, 'email')}
          />
        </Col>
        <Col span={6} sm={12}>
          <AmountInput
            labelKey="AMOUNT_ASTERISK"
            onChange={(value) => {
              handleChange({ ...userData.amount, ...value }, 'amount');
              isValidValue({ ...value }, 'amount');
            }}
            defaultValue={userData.defaultAmount}
            currency={currencyCode}
            large={false}
            title="the amount should be only numbers"
            disabled={!editable}
            className={styles.prePaymentAmount}
            isCharactersAllowed={false}
          />
          {editable && !isZeroMinMaxAmount ? (
            <Fragment>
              {minAmount.value > 0 ? (
                <Text
                  textKey="MIN_VALUE"
                  type={TextTypes.LABEL}
                  values={{
                  minValue:
                   minorToMajorUnitsFromCurrencyCode(minAmount.value, minAmount.currencyCode),
                   dash: maxAmount.value > 0 ? '-' : null
                 }}
                />
              ) : null}
              {maxAmount.value > 0 ? (
                <Text
                  textKey="MAX_VALUE"
                  type={TextTypes.LABEL}
                  values={{
                  maxValue:
                   minorToMajorUnitsFromCurrencyCode(maxAmount.value, maxAmount.currencyCode),
                 }}
                />
              ) : null}
            </Fragment>
        ) : null}
        </Col>
        <Col span={12} sm={12}>
          <div className={styles.payNowFooter}>
            <div style={{ paddingTop: '20px' }}>
              <Button
                type={ButtonTypes.PRIMARY}
                disabled={!allowPaySubmission(userData, editable, minAmount, maxAmount)}
                textKey="PAY_NOW"
                onClick={() => handleCreateOrder(invoiceRef, userData, setPayNowStatus)}
                loading={payNowStatus.loading}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

InputFields.propTypes = {
  maxAmount: PropTypes.shape({
    value: PropTypes.number,
    currencyCode: PropTypes.string,
  }),
  minAmount: PropTypes.shape({
    value: PropTypes.number,
    currencyCode: PropTypes.string,
  }),
  description: PropTypes.string,
  amount: PropTypes.shape({
    value: PropTypes.number,
    currencyCode: PropTypes.string,
  }),
  setPayNowStatus: PropTypes.func,
  payNowStatus: PropTypes.shape({}),
  editable: PropTypes.bool,
  invoiceRef: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
};

InputFields.defaultProps = {
  description: '',
  editable: false,
  setPayNowStatus: () => {},
  payNowStatus: {},
  amount: { value: 0, countryCode: 'AED' },
  minAmount: { value: 0, countryCode: 'AED' },
  maxAmount: { value: 0, countryCode: 'AED' },
};

export default InputFields;
