import React, { useContext, Fragment, useState, useEffect } from 'react';
import { oneOf } from 'prop-types';
import { Col, Row, Card } from 'ni-ui/layout';
import Text, { TextTypes } from 'ni-ui/text';
import Button, { ButtonTypes } from 'ni-ui/button';
import { Alert, NotificationType } from 'ni-ui/notification';
import { get, last } from 'lodash';
import styles from './styles.scss';
import { getRedirectToMerchantUrl } from '../common/utils';
import { AppContext } from '../app-context';

const getPaymentStatusDisplayData = (status, action) => {
  switch (status) {
    case 'POST_AUTH_REVIEW':
      return {
        description: 'PAYMENT_PROCESSING_DESCRIPTION',
        type: NotificationType.WARNING
      };

    case 'SUCCESS':
      if (action === 'VERIFY') {
        return {
          description: 'VERIFICATION_SUCCESS',
          type: NotificationType.SUCCESS
        };
      }
      return {
        description: 'PAYMENT_DESCRIPTION_ACCEPTED',
        type: NotificationType.SUCCESS
      };

    case 'CANCELLED':
      return {
        description: 'PAYMENT_DESCRIPTION_CANCELLED',
        type: NotificationType.ERROR
      };

    case 'PARTIAL_AUTH_DECLINE_FAILED':
      return {
        description: 'PAYMENT_DESCRIPTION_PARTIAL_AUTH_DECLINE_FAILED',
        type: NotificationType.ERROR
      };

    case 'FAILED':
    default:
      if (action === 'VERIFY') {
        return {
          description: 'VERIFICATION_FAILED',
          type: NotificationType.ERROR
        };
      }
      return {
        description: 'PAYMENT_DESCRIPTION_REJECTED',
        type: NotificationType.ERROR
      };
  }
};

const PaymentStatusPage = ({ status }) => {
  const { orderDetails, currentSplitPaymentCard } = useContext(AppContext);
  const {
    action,
    merchantOrderReference,
    reference,
    merchantAttributes,
    isSplitPayment
  } = orderDetails.order;
  const orderPayments = get(orderDetails, 'order._embedded.payment', []);
  const [isIntermediateStatusPage, setIsIntermediateStatusPage] = useState(false);
  const [isOrderStatus, setIsOrderStatus] = useState(true);

  useEffect(() => {
    if (isSplitPayment) {
      setIsIntermediateStatusPage(last(orderPayments).state === 'AWAIT_3DS');
      setIsOrderStatus(last(orderPayments).state !== 'AWAIT_3DS');
    }
  }, [orderDetails]);

  return (
    <Fragment>
      <Card inheritBackgroundColor>
        <div className={styles.paymenStatuswhiteContainer}>
          <Row>
            <Col>
              {(isIntermediateStatusPage) ?
                <Alert
                  type={getPaymentStatusDisplayData(status, action).type}
                  textKey="SPLIT_PAYMENT_DESCRIPTION_ACCEPTED"
                  values={{ cardPan: orderPayments[currentSplitPaymentCard].paymentMethod.pan }}
                />
                :
                <Alert
                  type={getPaymentStatusDisplayData(status, action).type}
                  textKey={getPaymentStatusDisplayData(status, action).description}
                />
              }
            </Col>
          </Row>
        </div>
        {isOrderStatus &&
          <div className={styles.redirectButtonFooter}>
            <div className={styles.paymentRef}>
              <Col span={5} sm={12}>
                <div className={styles.paymentRefText}>
                  <Text textKey="REFERENCE_NUMBER" type={TextTypes.BODY} />
                </div>
              </Col>
              <Col span={7} sm={12}>
                <div className={styles.paymentRefText}>
                  <Text
                    textKey={merchantOrderReference || reference}
                    type={TextTypes.BODY}
                    disableI18n
                  />
                </div>
              </Col>
            </div>
            <div className={styles.redirectButton}>
              {merchantAttributes.redirectUrl &&
                !merchantAttributes.noReturn && (
                  <Button
                    onClick={() => {
                      window.location.assign(getRedirectToMerchantUrl(orderDetails));
                    }}
                    type={ButtonTypes.PRIMARY}
                    textKey="RETURN_TO_MERCHANT"
                  />
                )}
            </div>
          </div>
        }
      </Card>
    </Fragment>
  );
};

PaymentStatusPage.propTypes = {
  status: oneOf(['SUCCESS', 'FAILED']).isRequired
};

export default PaymentStatusPage;
