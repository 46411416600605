import React, { useCallback, useContext, useMemo, useEffect, useState, useRef } from 'react';
import { Row, Col } from 'ni-ui/layout';
import isEqual from 'lodash/isEqual';
import Button from 'ni-ui/button';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import { PropTypes } from 'prop-types';
import CardInput, { validateCardDetails, cardTypesBasedOnLibShortcut, parseCardType } from 'ni-ui/card-input';
import { Alert, NotificationType } from 'ni-ui/notification';
import { CheckBox } from 'ni-ui/input/index';
import { style } from 'ni-ui/theme';
import { Option } from 'ni-ui/radio';
import Text, { TextTypes } from 'ni-ui/text';
import Colors from 'ni-ui/colors';
import Header from './cardHeader';
import { usePaymentMethodSetter } from '../payment-methods';
import useStateWithRef from '../../hooks/useStateWithRef';
import { usePost } from '../../hooks/network';
import { AppContext } from '../../../app-context';
import Installment from '../installment';
import { installmentOptions } from '../../../api';
import { getCardScheme, formatExpiry } from '../../../common/utils';
import styles from './styles.scss';

const applyCardInputLabelTheme = () => style`
  [lang="fr"] .card-input .input-group label {
    font-size: 11px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }
`('card-input-label');

function getFirstError(errors, defaultValue) {
  return errors[0] || defaultValue;
}

const Card = ({
  id, inputType,
  isSubChoice, isSubChoiceChecked,
  CheckBoxElement, cardHeaderTitle,
  setSplitPaymentCards,
  setSelectedSubChoice
}) => {
  const {
    orderDetails, setContextState, selectedCurrency,
    outletRef, orderRef,
    languageDirection, selectedInstallmentOption
  } = useContext(AppContext);
  const [cardState, setCardState, cardStateRef] = useStateWithRef({
    pan: '',
    expiry: '',
    cvv: '',
    cardholderName: '',
    errors: [],
  });

  const [cardNumber, setCardNumber] = useState('');
  const { showPayerName } = orderDetails.order.merchantAttributes;
  const isShowPayerNameEnabled = showPayerName === 'true';
  const cardTypeName = cardTypesBasedOnLibShortcut[parseCardType(cardStateRef.current.pan)];
  const [isSaveCardChecked, setIsSaveCardChecked] = useState(false);
  const saveCardCheckBoxValue = useRef(false);

  useEffect(() => {
    saveCardCheckBoxValue.current = isSaveCardChecked;
  }, [isSaveCardChecked]);

  const onChangeCardData = useCallback((data) => {
    const oldData = cardStateRef.current;

    if (data.creditCardNumber !== oldData.pan) {
      setCardState({ pan: data.creditCardNumber });
    }

    if (data.expiry !== oldData.expiry) {
      setCardState({ expiry: data.expiry });
    }

    if (data.securityCode !== oldData.cvv) {
      setCardState({ cvv: data.securityCode });
    }

    if (data.name !== oldData.cardHolderName && (!isShowPayerNameEnabled || isSubChoice)) {
      setCardState({ cardholderName: data.name });
    }

    if (!isEqual(sortBy(data.errors), sortBy(oldData.errors))) {
      setCardState({ errors: data.errors });
    }
  }, []);

  const onSubmitCardPayment = usePost(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/card`);

  const handelCardRequest = async () => {
    const {
      pan,
      cardholderName,
      expiry,
      cvv,
    } = cardStateRef.current;
    const cardType = parseCardType(pan);
    setCardState({ errors: [] });
    const { data } = await onSubmitCardPayment({
      cardholderName,
      pan: pan.replace(new RegExp(' ', 'g'), ''),
      expiry: formatExpiry(expiry),
      cvv,
      name: cardType && cardType.toUpperCase(),
      saveMyCard: saveCardCheckBoxValue.current,
      currency: orderDetails.order.amount.currencyCode,
      ...(selectedInstallmentOption ? {
        vis: {
          planSelectionIndicator: selectedInstallmentOption.numberOfInstallments !== 'FULL',
          vPlanId: selectedInstallmentOption.vPlanID,
          acceptedTAndCVersion: selectedInstallmentOption.termsAndConditions
        }
      } : {})
    });
    return data;
  };
  const handleAddCard = () => {
    const firstSix = cardState.pan.slice(0, 6);
    const lastFour = cardState.pan.slice(-4);
    setSplitPaymentCards({
      pan: cardState.pan.replace(/\s+/g, ''),
      maskedPan: `${firstSix}******${lastFour}`,
      expiry: formatExpiry(cardState.expiry),
      cardholderName: cardState.cardholderName,
      scheme: getCardScheme(cardState.pan),
      cvv: cardState.cvv,
      cardToken: Date.now().toString(),
      recaptureCsc: isSaveCardChecked,
      isAddedCard: true,
    });
    setIsSaveCardChecked(false);
    setSelectedSubChoice(false);
  };
  const onClickPayNow = useCallback(async () => {
    try {
      const data = await handelCardRequest();
      if (data.state === 'REATTEMPT_STARTED') {
        setContextState({
          orderDetails: {
            ...orderDetails,
            state: data.state,
            order: {
              ...orderDetails.order,
              paymentReference: data.order.paymentReference,
            }
          }
        });
      } else {
        setContextState({
          orderDetails: {
            ...orderDetails,
            state: data.state,
            order: data.order,
          }
        });
      }
    } catch (error) {
      if (error.response.data.errors[0].errorCode === 'cardNotSupportRecurringPayment') {
        throw new ReferenceError(error.response.data.errors[0].message);
      } else {
        throw new Error(error);
      }
    }
  }, [onSubmitCardPayment, orderDetails, selectedCurrency, selectedInstallmentOption]);

  const disablePayNow = useMemo(() => {
    const {
      pan,
      cardholderName,
      expiry,
      cvv,
    } = cardState;

    const [mm = '', yy = ''] = expiry.split('/');
    return validateCardDetails({
      pan,
      cardholderName,
      cvv,
      mm,
      yy,
      allowedCardTypes: orderDetails.paymentMethods.card
    }).size > 0;
  }, [cardState]);

  let selectedPaymentMethodName;

  if (!isSubChoice) {
    selectedPaymentMethodName = usePaymentMethodSetter({ onClickPayNow, disablePayNow, id: id || 'CARD' });
  }


  let message = orderDetails.state === 'REATTEMPT_STARTED' ? 'PAYMENT_DESCRIPTION_REJECTED' : 'PAYMENT_DESCRIPTION_CURRENCY_NOT_FOUND';

  if (orderDetails.walletProcessingError) {
    message = `PAYMENT_DESCRIPTION_${orderDetails.walletType}_ERROR`;
  }

  useEffect(() => {
    setCardState({
      errors: [],
      pan: '',
      expiry: '',
      cvv: '',
      cardholderName: '',
    });
    setCardNumber('');
  }, [selectedPaymentMethodName, isSubChoiceChecked]);

  useEffect(() => {
    if (orderDetails.state === 'REATTEMPT_STARTED' || orderDetails.state === 'MCP_FAILED') {
      setCardState({
        errors: [message],
        pan: '',
        expiry: '',
        cvv: '',
        cardholderName: '',
      });
    } else {
      setCardState({
        errors: [],
        pan: '',
        expiry: '',
        cvv: '',
        cardholderName: '',
      });
    }
  }, [orderDetails.order.paymentReference, orderDetails.state]);

  useEffect(() => {
    applyCardInputLabelTheme({ fontSize: '11px' });
  }, []);

  const { billingAddress } = orderDetails.order;
  useEffect(() => {
    if (isShowPayerNameEnabled && selectedPaymentMethodName === (id || 'CARD')) {
      setCardState({ cardholderName: `${billingAddress.firstName} ${billingAddress.lastName}` });
    }
  }, [selectedPaymentMethodName]);

  const fetchVisData = async (val) => {
    setCardNumber(val);
    if (orderDetails.order.orderCurrency !== 'AED' || selectedCurrency !== 'AED') {
      setContextState({ installmentDetails: [], panOrCardToken: '' });
      return;
    }
    if (selectedPaymentMethodName === (id || 'CARD') && !val) {
      setContextState({ installmentDetails: [], panOrCardToken: '' });
      return;
    }
    try {
      if (val !== cardNumber) {
        setContextState({ installmentSpinner: true });
        const { data } = await installmentOptions(
          outletRef,
          orderRef,
          orderDetails.order.paymentReference,
          {
            ...((selectedPaymentMethodName === (id || 'CARD') && val) ?
              { pan: val.replace(new RegExp(' ', 'g'), '') } : {}),
            ...((selectedPaymentMethodName === 'SAVEDCARD' && orderDetails.order.savedCard.cardToken) ?
              { cardToken: orderDetails.order.savedCard.cardToken } : {})
          }
        );
        setContextState({ installmentDetails: data, panOrCardToken: val });
        setContextState({ installmentSpinner: false });
      } else {
        setContextState({ installmentDetails: [], panOrCardToken: '' });
      }
    } catch (err) {
      setContextState({ installmentSpinner: false });
      setContextState({ installmentDetails: [], panOrCardToken: '' });
    }
  };

  return (
    <div className={styles.wFull} data-testid={id}>
      {cardState.errors.length !== 0 ?
        <Row>
          <Col>
            <Alert
              type={NotificationType.ERROR}
              textKey={getFirstError(cardState.errors, '')}
              values={{
                schemeName: cardTypeName
                  ? cardTypeName.charAt(0).toUpperCase() +
                    cardTypeName.slice(1).toLowerCase()
                  : 'this scheme',
              }}
            />
          </Col>
        </Row> : null}
      {/* This key here is a super hack to reset form, thanks to bad base component design! */}
      <Row key={orderDetails.order.paymentReference} gutter={false}>
        <Col gutter={false}>
          {inputType === 'checkbox' ?
            <div className={styles.checkBoxCardContainer}>
              {CheckBoxElement}
              <Header
                allowedCardTypes={orderDetails.paymentMethods.card}
                cardHeaderTitle={cardHeaderTitle}
              />
            </div> :
            <Option id={id || 'CARD'} label="card">
              <Header allowedCardTypes={orderDetails.paymentMethods.card} cardHeaderTitle={cardHeaderTitle} />
            </Option>
          }
          {((!isSubChoice && selectedPaymentMethodName === (id || 'CARD')) || isSubChoiceChecked) &&
            <Row gutter={false}>
              <Col gutter={false} span={8} md={12} sm={12}>
                <div data-testid="card-details" style={{ padding: '20px 0' }}>
                  <CardInput
                    onCardInputBlur={fetchVisData}
                    onChange={onChangeCardData}
                    direction={languageDirection}
                    maskPan={get(orderDetails, 'order.merchantAttributes.maskPaymentInfo', null) === 'true'}
                    showPayerName={isShowPayerNameEnabled && !isSubChoice}
                    cardData={isShowPayerNameEnabled ? { name: `${billingAddress.firstName} ${billingAddress.lastName}` } : {}}
                    allowedCardTypes={orderDetails.paymentMethods.card}
                  />
                  {orderDetails.manageMyCards.saveMyCardEnabled &&
                    <div className={styles.checkBoxContainer}>
                      <CheckBox
                        color={Colors.GREY_DARK}
                        disabled={false}
                        selected={isSaveCardChecked}
                        onClick={() => setIsSaveCardChecked(!isSaveCardChecked)}
                      />
                      <div className={styles.checkBoxText}>
                        <Text type={TextTypes.BODY} textKey="SAVE_CARD_CHECKBOX_TEXT" />
                      </div>
                    </div>
                  }
                </div>
              </Col>
              {isSubChoice && (
              <Button
                onClick={handleAddCard}
                disabled={disablePayNow}
                textKey="ADD_CARD"
              />
            )}
            </Row>
          }
          {selectedPaymentMethodName === (id || 'CARD') && <Installment cardStateRef={cardStateRef || {}} />}
        </Col>
      </Row>
    </div>
  );
};

Card.propTypes = {
  inputType: PropTypes.string,
  id: PropTypes.string,
  isSubChoice: PropTypes.bool,
  isSubChoiceChecked: PropTypes.bool,
  CheckBoxElement: PropTypes.node,
  cardHeaderTitle: PropTypes.string,
  setSplitPaymentCards: PropTypes.func,
  setSelectedSubChoice: PropTypes.func,
};

Card.defaultProps = {
  inputType: 'radio',
  id: '',
  isSubChoice: false,
  isSubChoiceChecked: false,
  CheckBoxElement: null,
  cardHeaderTitle: '',
  setSplitPaymentCards: () => {},
  setSelectedSubChoice: () => {}
};

export default Card;
