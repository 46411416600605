import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import Text, { TextTypes } from 'ni-ui/text';
import COLORS from 'ni-ui/colors';
import GooglePayButton from '@google-pay/button-react';
import styles from './styles.scss';
import { usePaymentMethodSetter } from '../payment-methods';
import GooglePayIcon from '../../../images/google-pay.svg';
import useGooglePay from './useGooglePay';

const GooglePay = () => {
  const {
    googlePayConfig, isGooglePayProcessing, handleAcceptGooglePayTransaction, orderDetails
  } = useGooglePay();

  const onClickPayNow = useCallback(async () => {
    document.getElementById('gpay-button-online-api-id').click();
  }, []);

  usePaymentMethodSetter({ onClickPayNow, paymentProcessing: isGooglePayProcessing, id: 'GOOGLE_PAY' });
  return (
    <div className={styles.googlepayContainer} data-testid="google-pay">
      <Text
        textKey="GOOGLE_PAY"
        textColor={COLORS.GREY_VERY_DARK}
        type={TextTypes.MENU_LEVEL_2}
      />
      <div >
        <img src={GooglePayIcon} width="100px" alt="Google Pay" />
      </div>
      <div style={{ display: 'none' }}>
        {!isEmpty(googlePayConfig) ? (
          <GooglePayButton
            environment={googlePayConfig.environment}
            paymentRequest={{
              apiVersion: 2,
              apiVersionMinor: 0,
              allowedPaymentMethods: [
                {
                  type: 'CARD',
                  parameters: {
                    allowedAuthMethods: googlePayConfig.allowedAuthMethods,
                    allowedCardNetworks: googlePayConfig.allowedPaymentMethods,
                  },
                  tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                      gateway: googlePayConfig.gatewayName,
                      gatewayMerchantId: googlePayConfig.merchantGatewayId,
                    },
                  },
                },
              ],
              merchantInfo: {
                merchantId: googlePayConfig.merchantGatewayId,
                merchantName: googlePayConfig.merchantInfo.name,
                merchantOrigin: googlePayConfig.merchantOrigin
              },
              transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: orderDetails.order.amount.formattedValue,
                currencyCode: orderDetails.order.amount.currencyCode,
              },
            }}
            onLoadPaymentData={(paymentRequest) => {
              const { token } = paymentRequest.paymentMethodData.tokenizationData;
              handleAcceptGooglePayTransaction(token);
          }}
          />
        ) : null }
      </div>
    </div>
  );
};

export default GooglePay;
