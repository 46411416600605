import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  lazy,
  Suspense,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import { Alert, NotificationType } from 'ni-ui/notification';
import get from 'lodash/get';
import truncate from 'lodash/truncate';
import { Col, Row } from 'ni-ui/layout';
import Hr from 'ni-ui/hr';
import Text, { TextTypes } from 'ni-ui/text';
import Button, { ButtonTypes } from 'ni-ui/button';
import Radio, { Option } from 'ni-ui/radio';
import { CheckBox } from 'ni-ui/input';
import Colors from 'ni-ui/colors';
import styles from './styles.scss';
import { AppContext } from '../../app-context';
import SecureIcons from './secure-icons';
import SavedCard from './saved-card';
import ChinaUnionPay from './china-union-pay';
import Aani from './aani';
import SamsungPay from './samsung-pay';
import GooglePay from './google-pay';
import ApplePay, { isApplePayAvailable } from './apple-pay';
import CardFallback from './card/card-fallback';
import {getCanceUrl, getFromStorage, isSavedCard, setInLocalStorage} from '../../common/utils';
import DropDown, { Option as DropDownOption } from '../dropdown';
import Tabby from './tabby';

import Installment from './installment';
import Ozow from './ozow';
import SavedCardRecaptureCsc from './saved-card/saved-card-recapture-csc';
import { formatOrder, nonMcpSupportedPaymentMethods } from '../../utils';
import SplitPayment from './split-payment';

const Card = lazy(() => import('./card'));
const UkhesheScanToPay = lazy(() => import('./ukheshe-scanToPay'));

const PaymentMethodsContext = React.createContext();

const hasWalletPaymentOption = orderDetails => orderDetails.paymentMethods.wallet &&
  orderDetails.paymentMethods.wallet.length !== 0;

const hasApmPaymentOption = orderDetails => orderDetails.paymentMethods.apm &&
  orderDetails.paymentMethods.apm.length !== 0;

const numOfPaymentMethodsAvailable = (orderDetails) => {
  const allowedPaymentMethods = [];
  if (orderDetails.order.savedCard) {
    allowedPaymentMethods.push('SAVEDCARD');
  }
  if (orderDetails.paymentMethods.card) {
    allowedPaymentMethods.push('CARD');
  }
  return allowedPaymentMethods;
};

const findDefaultSelectedPaymentOption = (orderDetails) => {
  if (orderDetails.order.savedCard) {
    return 'SAVEDCARD';
  }
  if (orderDetails.order.savedCards && !orderDetails.order.savedCard) {
    return 'SAVEDCARDS0';
  }
  if (orderDetails.paymentMethods.card) {
    return 'CARD';
  }
  if (hasApmPaymentOption(orderDetails)) {
    return orderDetails.paymentMethods.apm[0];
  }
  if (hasWalletPaymentOption(orderDetails)) {
    return orderDetails.paymentMethods.wallet[0];
  }
  return 'CARD';
};

const isApmEnabled = (apm, type) => (orderDetails) => {
  const apms = get(orderDetails.paymentMethods, type, []);
  return apms.filter(eachApm => eachApm === apm).length > 0;
};

const isSamsungEnabled = isApmEnabled('SAMSUNG_PAY', 'wallet');

const isApplePayEnabled = isApmEnabled('APPLE_PAY', 'wallet');

const isGooglePayEnabled = isApmEnabled('GOOGLE_PAY', 'wallet');

const isCupEnabled = isApmEnabled('CHINA_UNION_PAY', 'apm');

const isAaniEnabled = isApmEnabled('AANI', 'apm');

const isTabbyEnabled = isApmEnabled('TABBY', 'apm');

const isOzowEnabled = isApmEnabled('OZOW', 'apm');

const isUkhehseScanToPayEnabeled = isApmEnabled('UKHESHE', 'apm');

const usePaymentMethodSetter = ({
  onClickPayNow, disablePayNow, id, paymentProcessing
}) => {
  const {
    setSelectedPaymentMethod,
    name: selectedPaymentMethodName
  } = useContext(PaymentMethodsContext);

  useEffect(() => {
    if (selectedPaymentMethodName === id) {
      setSelectedPaymentMethod({
        name: id,
        onClickPayNow,
        paymentProcessing
      });
    }
  }, [onClickPayNow, paymentProcessing, selectedPaymentMethodName, id]);

  useEffect(() => {
    if (selectedPaymentMethodName === id) {
      setSelectedPaymentMethod({
        disablePayNow,
      });
    }
  }, [disablePayNow, selectedPaymentMethodName, id]);

  return selectedPaymentMethodName;
};

const PaymentMethods = () => {
  const [loading, setLoading] = useState();
  const [paymentErr, setPaymentErr] = useState(false);
  const [tabbyPaymentErr, setTabbyPaymentErr] = useState(false);
  const [tabbyPaymentErrorMessage, setTabbyPaymentErrorMessage] = useState();
  const [splitPaymentErr, setSplitPaymentErr] = useState(false);
  const [splitPaymentErrMessage, setSplitPaymentErrMessage] = useState('');
  const {
    orderDetails,
    selectedCurrency,
    selectedInstallmentOption,
    installmentDetails,
    deleteCardResult,
    setContextState,
    refetchOrder
  } = useContext(AppContext);
  const cancelUrl = get(orderDetails, 'order.merchantAttributes.cancelUrl', '');
  const cancelText = get(orderDetails, 'order.merchantAttributes.cancelText', 'Continue Shopping');
  const isSplitPaymentEnabled = get(orderDetails, 'showSplitPaymentOption');
  const buttonCancelText = truncate(cancelText, { length: 30, separator: '...' });
  const [selectedPaymentMethod, setPM] = useState({
    name: findDefaultSelectedPaymentOption(orderDetails),
    onClickPayNow: null,
    disablePayNow: true,
    disableApms: false,
    paymentProcessing: false
  });
  const [aaniErrors, setAaniErrors] = useState('');
  const setSelectedPaymentMethod = useCallback(data =>
    setPM(oldState => ({
      ...oldState,
      ...data
    })), []);

  const { onClickPayNow, disablePayNow, paymentProcessing } = selectedPaymentMethod;

  const paymentMethodContextValue = useMemo(() => ({
    ...selectedPaymentMethod,
    setSelectedPaymentMethod
  }), [selectedPaymentMethod]);


  const [hasAcceptedTnc, setHasAcceptedTnc] = useState(() => (!orderDetails.tncUrl));

  const multipleOptionAvailable = useMemo(() =>
    numOfPaymentMethodsAvailable(orderDetails).length > 1, [orderDetails]);

  useEffect(() => {
    if (get(orderDetails, 'order.baseCurrency', null) !== selectedCurrency
      && selectedPaymentMethod.name !== 'SAVEDCARD') {
      setSelectedPaymentMethod({ name: 'CARD', disableApms: true });
    } else {
      setSelectedPaymentMethod({ disableApms: false });
    }
  }, [orderDetails, selectedCurrency]);

  const hasUnmounted = useRef(false);

  useEffect(() => () => { hasUnmounted.current = true; }, []);

  const onClickPayNowHandler = useCallback(async () => {
    try {
      setLoading(true);
      setPaymentErr(false);
      setTabbyPaymentErr(false);
      setSplitPaymentErr(false);
      if (selectedPaymentMethod.name === 'AANI') {
        const codeFromStorage = getFromStorage('code');
        const authDataFromStorage = getFromStorage('auth');
        if (codeFromStorage != null) {
          setInLocalStorage('code', codeFromStorage);
          setInLocalStorage('auth', authDataFromStorage);
        }
      }
      await selectedPaymentMethod.onClickPayNow();
    } catch (err) {
      if (selectedPaymentMethod.name === 'SPLIT_PAYMENT') {
        const errorMessage = get(
          err,
          'response.data.errors[0].message',
          'Some error occured while performing Split Payment.'
        );
        setSplitPaymentErr(true);
        setSplitPaymentErrMessage(errorMessage);
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else if (err instanceof ReferenceError) {
        setTabbyPaymentErr(true);
        setTabbyPaymentErrorMessage(err.message);
      } else {
        setPaymentErr(true);
      }
    } finally {
      if (!hasUnmounted.current) {
        setLoading(false);
      }
    }
  }, [onClickPayNow]);

  const {
    totalAmt,
  } = useMemo(() => formatOrder(orderDetails, selectedPaymentMethod.name),
    [orderDetails.order, selectedPaymentMethod.name]);

  const [computing, setComputing] = useState(false);

  const recomputeCurrency = useCallback(async (currencySelected) => {
    try {
      setComputing(true);
      await refetchOrder(currencySelected);
    } catch (err) {
      // eslint-disable-line
    } finally {
      setComputing(false);
    }
  }, []);

  const installmentOptionCheck = () => {
    if (!get(installmentDetails, 'matchedPlans', []).length) {
      return true;
    }
    if (installmentDetails && get(installmentDetails, 'matchedPlans', []).length && !selectedInstallmentOption) {
      return false;
    }

    return true;
  };

  const showSplitPayment = details => isSplitPaymentEnabled && ['PURCHASE'].includes(details.order.action) &&
    details.order.type === 'SINGLE';

  useEffect(() => {
    setContextState({ currentSelectedPaymentMethod: selectedPaymentMethod.name })
    if (selectedInstallmentOption) {
      setContextState({ selectedInstallmentOption: null });
    }
  }, [selectedPaymentMethod.name]);


  useEffect(() => {
    if (selectedCurrency) {
      recomputeCurrency(selectedCurrency);
    }
  }, [selectedCurrency]);

  useEffect(() => {
    let removeContextData;
    if (deleteCardResult) {
      removeContextData = setTimeout(() => {
        setContextState({ deleteCardResult: {} });
      }, 4000);
    }
    return () => {
      if (removeContextData) {
        clearTimeout(removeContextData);
      }
    };
  }, [orderDetails]);

  const setCurrency = currency => setContextState({ selectedCurrency: currency });
  const { name, disableApms } = selectedPaymentMethod;
  const cscRecapture = orderDetails.order.state === 'AWAIT_CSC_RECAPTURE';

  const getButtonTextKey = () => {
    if(isSavedCard(orderDetails)){
      return 'DONE'
    }
    return get(installmentDetails, 'matchedPlans', []).length ? 'MAKE_PAYMENT'
      : cscRecapture ? 'CONTINUE_PAYMENT' : 'PAY_NOW_AMOUNT';
  };

  return (
    <Row gutter={false}>
      <Col gutter={false}>
        <PaymentMethodsContext.Provider value={paymentMethodContextValue}>
          <div className={`${styles.inheritWidth} appRoot`}>
            {multipleOptionAvailable &&
              <Row>
                <Col>
                  <div className={styles.acceptPaymentHeader}>
                    <Text type={TextTypes.H3} textKey={cscRecapture ? 'ADD_CARD_CVV' : 'SELECT_PAYMENT'} />
                    <Hr />
                  </div>
                </Col>
              </Row>}
            {tabbyPaymentErr ?
              <Row>
                <Col>
                  <Alert type={NotificationType.ERROR} textKey="TABBY_PAYMENT_ERROR" values={{ rejectionReason: tabbyPaymentErrorMessage }} />
                </Col>
              </Row> : null}
            {splitPaymentErr ?
              <Row>
                <Col>
                  <Alert type={NotificationType.ERROR} textKey="SPLIT_PAYMENT_ERROR" values={{ errorMessage: splitPaymentErrMessage }} />
                </Col>
              </Row> : null}
            {deleteCardResult ?
              <Row>
                <Col>
                  <Alert
                    type={deleteCardResult.type === 'SUCCESS' ? NotificationType.SUCCESS : NotificationType.ERROR}
                    textKey={deleteCardResult.message}
                  />
                </Col>
              </Row> : null}
            {paymentErr || aaniErrors ?
              <Row>
                <Col>
                  <Alert type={NotificationType.ERROR} textKey={aaniErrors || 'PAYMENT_ERROR'} />
                </Col>
              </Row> : null}
            {cscRecapture &&
              <Suspense fallback={<h1>Loading..</h1>}>
                <SavedCardRecaptureCsc card={orderDetails.order.savedCard} id="SAVEDCARD_RECAPTURE_CSC" />
              </Suspense>}
            {!cscRecapture &&
            <Radio
              disabledMessage={orderDetails.walletProcessingError ? 'PAYMENT_METHOD_UNAVAILABLE' : 'PAYMENT_METHOD_DISABLED_MESSAGE'}
              selected={name}
              align="LEFT"
              onChange={id => setSelectedPaymentMethod({ name: id })}
            >
              <Row gutter={false}>
                <Col gutter={false}>
                  {!!orderDetails.order.savedCard &&
                    <Suspense fallback={<h1>Loading..</h1>}>
                      <Option label="saved card" id="SAVEDCARD">
                        <SavedCard card={orderDetails.order.savedCard} />
                      </Option>
                      {selectedPaymentMethod.name === 'SAVEDCARD' ? <Installment cardStateRef={{}} /> : null}
                      <Hr />
                    </Suspense>}

                  {(!orderDetails.order.savedCard && orderDetails.order.savedCards) &&
                    <Suspense fallback={<h1>Loading..</h1>}>
                      {orderDetails.order.savedCards.map((singleCard, index) => (
                        <Fragment>
                          <Option label={`saved card${index}`} id={`SAVEDCARDS${index}`}>
                            <SavedCard card={singleCard} index={index} />
                          </Option>
                          {selectedPaymentMethod.name === `SAVEDCARDS${index}` ? <Installment cardStateRef={{}} /> : null}
                          <Hr />
                        </Fragment>
                      ))}
                    </Suspense>}
                  {orderDetails.paymentMethods.card &&
                    <Suspense fallback={<CardFallback />}>
                      <Card shouldExpand={!multipleOptionAvailable}
                            cardHeaderTitle={isSavedCard(orderDetails) && 'SAVED_CARD_TITLE'}/>
                      {selectedPaymentMethod.name === 'CARD' && (hasApmPaymentOption(orderDetails) || hasWalletPaymentOption(orderDetails)) && <Hr />}
                    </Suspense>}
                  {isUkhehseScanToPayEnabeled(orderDetails) &&
                    <Suspense fallback={<h1>Loading..</h1>}>
                      <Option disabled={disableApms} id="UKHESHE" label="Ukheshe">
                        <UkhesheScanToPay />
                      </Option>
                    </Suspense>
                  }
                  {showSplitPayment(orderDetails) &&
                  <Suspense fallback={<h1>Loading..</h1>}>
                    <SplitPayment order={orderDetails.order} />
                  </Suspense>
                  }
                  {isAaniEnabled(orderDetails) &&
                    <Suspense fallback={<h1>Loading..</h1>}>
                      <Aani shouldExpand={!multipleOptionAvailable} errors={aaniErrors} setErrors={setAaniErrors} id="AANI" />
                    </Suspense>
                  }
                  {isCupEnabled(orderDetails) &&
                    <Suspense fallback={<h1>Loading..</h1>}>
                      <Option disabled={disableApms} label="china union pay" id="CHINA_UNION_PAY">
                        <ChinaUnionPay />
                      </Option>
                    </Suspense>}
                  {isGooglePayEnabled(orderDetails) &&
                    <Suspense fallback={<h1>Loading..</h1>}>
                      <Option disabled={disableApms} id="GOOGLE_PAY" label="google pay">
                        <GooglePay />
                      </Option>
                    </Suspense>}
                  {isSamsungEnabled(orderDetails) &&
                    <Suspense fallback={<h1>Loading..</h1>}>
                      <Option disabled={disableApms} id="SAMSUNG_PAY" label="samsung pay">
                        <SamsungPay />
                      </Option>
                    </Suspense>}
                  {isApplePayAvailable() && isApplePayEnabled(orderDetails)
                    &&
                    <Suspense fallback={<h1>Loading..</h1>}>
                      <Option
                        disabled={disableApms || (orderDetails.walletProcessingError && orderDetails.walletType === 'APPLE_PAY')}
                        id="APPLE_PAY"
                        label="apple pay"
                      >
                        <ApplePay />
                      </Option>
                    </Suspense>
                  }
                  {isTabbyEnabled(orderDetails) &&
                    <Suspense fallback={<h1>Loading..</h1>}>
                      <Tabby
                        padding="20px 0 20px 0"
                        tabbyType="INSTALLMENTS"
                        disabled={disableApms}
                        title="PAY_INSTALLMENTS"
                        description="PAY_INSTALLMENTS_DESC"
                        label="tabbyInstallments"
                      />
                    </Suspense>}
                  {isOzowEnabled(orderDetails) &&
                    <Suspense fallback={<h1>Loading..</h1>}>
                      <Ozow disabled={disableApms} title="OZOW" />
                    </Suspense>}
                </Col>
              </Row>
            </Radio>}
            <div>
              {orderDetails.tncUrl &&
                <Row gutter={false}>
                  <div style={{ paddingTop: '30px', paddingBottom: '30px', display: 'flex' }}>
                    <CheckBox
                      selected={hasAcceptedTnc}
                      color={Colors.GREY_DARK}
                      onClick={() => setHasAcceptedTnc(!hasAcceptedTnc)}
                    />
                    <a className={styles.tc} href={orderDetails.tncUrl} target="_blank">
                      <Text textKey="TERMS_AND_CONDITIONS" />
                    </a>
                  </div>
                </Row>}
              {cscRecapture &&
                <Row gutter={false}>
                  <div style={{ paddingBottom: '30px', display: 'flex' }}>
                    <Text textKey="CAPTURE_CVV_FOR_SECURITY" />
                  </div>
                </Row>}
              <Row>
                {orderDetails.mcpEnabled && !nonMcpSupportedPaymentMethods.includes(selectedPaymentMethod.name) &&
                  <Col span={6} sm={12}>
                    <div style={{ paddingTop: '20px', paddingBottom: '30px', display: 'flex' }}>
                      <Row gutter={false}>
                        <div className={styles.mcpContainer}>
                          <DropDown name="SELECT_CURRENCY" selected={selectedCurrency} setSelected={setCurrency}>
                            {orderDetails.currencies.map(currency =>
                              <DropDownOption option={currency} key={currency} />)}
                          </DropDown>
                        </div>
                      </Row>
                    </div>
                  </Col>}
                <Col span={6} sm={12}>
                  <div className={styles.buttonDiv}>
                    <Button
                      loading={loading || computing || paymentProcessing || orderDetails.order.state === 'AWAIT_3DS'}
                      onClick={onClickPayNowHandler}
                      disabled={disablePayNow ||
                        !hasAcceptedTnc ||
                        computing ||
                        !installmentOptionCheck()}
                      textKey={getButtonTextKey()}
                      textValues={{ totalAmt }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.payNowFooter}>
            <div
              style={{ paddingTop: '20px' }}
              title={cancelText}
            >
              {cancelUrl &&
                <Button
                  type={ButtonTypes.LINK}
                  onClick={() => {
                    window.location.assign(getCanceUrl(orderDetails));
                  }}
                >
                  <Text disableI18n textKey={buttonCancelText} />
                </Button>
              }
            </div>
          </div>
          <SecureIcons
           cards={get(orderDetails, 'paymentMethods.card', [])}
            isArabic={orderDetails.order.language === 'ar'}
          />
        </PaymentMethodsContext.Provider>
      </Col>
    </Row >
  );
};

export { PaymentMethodsContext, usePaymentMethodSetter };
export default PaymentMethods;
