import React, { useContext, lazy, Suspense, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { Col, Container, Row } from 'ni-ui/layout';
import { get } from 'lodash';
import Switch, { Case } from './components/switch';
import { AppContext } from './app-context';
import locale from './locale';
import Fallback from './components/fallback';
import styles from './styles.scss';
import useDocumentTitle from './components/hooks/useDocumentTitle';
import BrandingHeader from './components/branding-header';
import { getRedirectToMerchantUrl } from './common/utils';
import BrandingFooter from './components/branding-footer/branding-footer';
import TAF from './components/TAF/taf';

const PaymentPage = lazy(() => import('./payment-page'));
const PaymentStatusPage = lazy(() => import('./payment-status-page'));
const ThreeDSOne = lazy(() => import('./components/three-ds/three-ds-one'));
const ThreeDSTwo = lazy(() => import('./components/three-ds/three-ds-two'));
const PartialAuthConfirmationPage = lazy(() => import('./partial-auth-page'));

const getDocumentTitle = (condition, orderDetails) => {
  if (condition === 'STARTED' || condition === 'AWAIT_3DS' || condition === 'MCP_FAILED') {
    const { currencyCode, formattedValue } = orderDetails.order.amount;
    return `Please pay ${currencyCode} ${formattedValue}`;
  }
  return 'Payment result';
};

const Pages = () => {
  const {
    orderDetails,
    languageDirection,
    customFallBackMsg,
    setContextState,
    currentSplitPaymentCard
  } = useContext(AppContext);
  const threeDsData = useMemo(
    () => (
      orderDetails.order.threeDs
        ? { threeDs: orderDetails.order.threeDs, threeDs2: null }
        : { threeDs2: orderDetails.order.threeDs2, threeDs: null }
    ),
    [orderDetails]
  );

  const makeDelayAndReRenderComponent = async () => {
    await new Promise(resolve => setTimeout(resolve, 3000));
    setContextState({ ...orderDetails, currentSplitPaymentCard: currentSplitPaymentCard + 1 });
  };

  const handelSplitPayments = () => {
    const orderPayments = get(orderDetails, 'order._embedded.payment', []);
    let orderPaymentState;

    if (orderPayments[currentSplitPaymentCard].state === 'AWAIT_3DS' && threeDsData.threeDs2 === null) {
      orderPaymentState = 'AWAIT_3DS_1';
    } else if (orderPayments[currentSplitPaymentCard].state === 'AWAIT_3DS' && threeDsData.threeDs === null) {
      orderPaymentState = 'AWAIT_3DS_2';
    } else {
      orderPaymentState = orderPayments[currentSplitPaymentCard].state;
      if (currentSplitPaymentCard < orderPayments.length - 1) {
        makeDelayAndReRenderComponent();
      } else {
        return orderDetails.state;
      }
    }
    return orderPaymentState;
  };

  const isSovCouponPaymentPresent = () => {
    const orderPayments = get(orderDetails, 'order._embedded.payment', []);
    return orderPayments
      .filter(p => ['SOV', 'COUPON'].includes(get(p, 'paymentMethod.name', ''))).length > 0;
  };

  const condition = useMemo(() => {
    if (orderDetails.order.isSplitPayment && !isSovCouponPaymentPresent()) {
      return handelSplitPayments();
    }

    if (orderDetails.state === 'AWAIT_3DS' && threeDsData.threeDs2 === null) {
      return 'AWAIT_3DS_1';
    }
    if (orderDetails.state === 'AWAIT_3DS' && threeDsData.threeDs === null) {
      return 'AWAIT_3DS_2';
    }
    return orderDetails.state;
  }, [orderDetails, threeDsData, currentSplitPaymentCard]);

  const { order = { merchantAttributes: {} } } = orderDetails;
  const { merchantAttributes: { redirectUrl = '', skipConfirmationPage = 'false' } } = order;
  const shouldRedirect = skipConfirmationPage === 'true';
  const merchantRedirectUrl = useMemo(() =>
    getRedirectToMerchantUrl(orderDetails), [redirectUrl, skipConfirmationPage, condition]);

  const docTitle = useMemo(() => getDocumentTitle(condition, orderDetails), [
    condition,
    orderDetails
  ]);
  useDocumentTitle(docTitle);

  return (
    <IntlProvider
      locale={orderDetails.order.language}
      messages={locale[orderDetails.order.language]}
    >
      <div
        className={styles.page}
        dir={languageDirection}
        lang={orderDetails.order.language}
      >
        <div className={`${styles.main} ${styles.mainContainer} appRoot`} role="main">
          <Container>
            <Row>
              <Col span={8} sm={12} md={10} offset={{ lg: 4, md: 2, sm: 0 }}>
                <BrandingHeader isPaymentPage />
                <Switch condition={condition}>
                  <Case id={['AWAIT_TAF']}>
                    {() => (
                      <Suspense fallback={<Fallback message="NORMAL_LOADING_TEXT" />}>
                        <TAF TAFData={orderDetails.order?.tafAuthenticationPayload} />
                      </Suspense>)}
                  </Case>
                  <Case id={['AWAIT_3DS_1']}>
                    {() => (
                      <Suspense fallback={<Fallback message="NORMAL_LOADING_TEXT" />}>
                        <ThreeDSOne threeDsData={threeDsData.threeDs} />
                      </Suspense>)}
                  </Case>
                  <Case id={['AWAIT_CSC_RECAPTURE']}>
                    {() => (
                      <Suspense fallback={<Fallback message="NORMAL_LOADING_TEXT" />}>
                        <PaymentPage />
                      </Suspense>
                    )}
                  </Case>
                  <Case id={['STARTED', 'PENDING', 'REATTEMPT_STARTED', 'MCP_FAILED', 'AWAIT_3DS_2']}>
                    {() => {
                      if (customFallBackMsg) {
                        return <Fallback message="CUSTOM_PENDING_ANNI_PAYMENT_MESSAGE" />;
                      }
                      return (
                        <Suspense fallback={<Fallback message="NORMAL_LOADING_TEXT" />}>
                          <ThreeDSTwo state={condition} threeDsData={threeDsData.threeDs2}>
                            <PaymentPage />
                          </ThreeDSTwo>
                        </Suspense>
                      );
                    }}
                  </Case>
                  <Case id={['CAPTURED', 'AUTHORISED', 'PURCHASED', 'VERIFIED', 'PARTIALLY_AUTHORISED']}>
                    {() => {
                      if (shouldRedirect) {
                        window.location.replace(merchantRedirectUrl);
                        return <Fallback message={customFallBackMsg || 'NORMAL_LOADING_TEXT'} />;
                      }
                      return (
                        <Suspense fallback={<Fallback message={customFallBackMsg || 'NORMAL_LOADING_TEXT'} />}>
                          <PaymentStatusPage status="SUCCESS" />
                        </Suspense>
                      );
                    }}
                  </Case>
                  <Case id={['FAILED', 'POST_AUTH_REVIEW', 'CANCELLED', 'PARTIAL_AUTH_DECLINED', 'PARTIAL_AUTH_DECLINE_FAILED', 'REFUNDED', 'REVERSED']}>
                    {() => {
                      if (shouldRedirect) {
                        window.location.replace(merchantRedirectUrl);
                        return <Fallback message="NORMAL_LOADING_TEXT" />;
                      }
                      return (
                        <Suspense fallback={<Fallback message="NORMAL_LOADING_TEXT" />}>
                          <PaymentStatusPage status={condition} />
                        </Suspense>
                      );
                    }}
                  </Case>
                  <Case id={['AWAITING_PARTIAL_AUTH_APPROVAL']}>
                    {() => (
                      <Suspense fallback={<Fallback message="NORMAL_LOADING_TEXT" />}>
                        <PartialAuthConfirmationPage />
                      </Suspense>)}
                  </Case>
                </Switch>
                <BrandingFooter isPaymentPage  data-testid='branding-footer' />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </IntlProvider>
  );
};

export default Pages;
