/* eslint no-restricted-syntax: 0 */
import React, { useContext, useEffect, useRef, useCallback } from 'react';
import { Card } from 'ni-ui/layout';
import { shape } from 'prop-types';
import { AppContext } from '../../app-context';
import styles from './styles.scss';

const ThreeDSOne = ({ threeDsData }) => {
  const { refetchOrder } = useContext(AppContext);
  const {
    acsPaReq,
    acsUrl,
    threeDsCallback,
    acsMd
  } = threeDsData;

  const termUrl = threeDsCallback.replace('http://transaction-service', document.location.origin);

  const fetch3dsFormBtnRef = useRef();
  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      fetch3dsFormBtnRef.current.click();
    }
  });

  const messageReceiver = useCallback((message) => {
    if (message.data === '3DS_COMPLETE') {
      refetchOrder();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', messageReceiver);
    return () => {
      window.removeEventListener('message', messageReceiver);
    };
  }, []);

  // This cannot be done any other way.
  // dont mess around with this.
  return (
    <Card inheritBackgroundColor>
      <form action={acsUrl} className={styles.hidden} method="post" target="3ds_iframe">
        <input type="hidden" name="PaReq" value={acsPaReq} />
        <input type="hidden" name="TermUrl" value={termUrl} />
        <input type="hidden" name="MD" value={acsMd} />
        <input type="submit" ref={fetch3dsFormBtnRef} />
      </form>
      <iframe data-testid="3ds_iframe" name="3ds_iframe" id="3ds_iframe" title="3DS" className={styles.threeDSIframe} />
    </Card>
  );
};

ThreeDSOne.propTypes = {
  threeDsData: shape({}),
};

ThreeDSOne.defaultProps = {
  threeDsData: null,
};

export default ThreeDSOne;
