import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import COLORS from 'ni-ui/colors';
import { Col, Row, Container, Card } from 'ni-ui/layout';
import { Accordion, AccItem, AccItemBody, AccItemTitle } from 'ni-ui/accordion';
import Text, { TextTypes } from 'ni-ui/text';
import styles from './styles.scss';
import OrderTitle from './order-title';
import { AppContext } from '../../app-context';
import LineItem from './line-item';
import mainStyles from '../../styles.scss';
import { formatOrder } from '../../utils';

const OrderInfo = () => {
  const {
    orderDetails,
    currentSelectedPaymentMethod,
    selectedCurrency
  } = useContext(AppContext);
  const { order, mcpEnabled } = orderDetails;
  const {
    items,
    shippingAmt,
    taxAmt,
    totalAmt,
  } = useMemo(() => formatOrder(orderDetails, currentSelectedPaymentMethod),
    [orderDetails?.order, currentSelectedPaymentMethod]);

  const [computing, setComputing] = useState(false);

  const recomputeCurrency = useCallback(async () => {
    try {
      setComputing(true);
    } catch (err) {
      // eslint-disable-line
    } finally {
      setComputing(false);
    }
  }, []);

  useEffect(() => {
    if (selectedCurrency) {
      recomputeCurrency(selectedCurrency);
    }
  }, [selectedCurrency]);

  return (
    <Card inheritBackgroundColor>
      <Accordion style={{ backgroundColor: '#384751' }}>
        <AccItem id={0}>
          <AccItemTitle titleKey="ORDER_SUMMARY">
            <OrderTitle mcpEnabled={mcpEnabled} total={totalAmt} />
          </AccItemTitle>
          <AccItemBody>
            <div className={styles.accordionBody}>
              <Container>
                <Row>
                  <div className={styles.whiteRule} />
                </Row>
              </Container>
              {items.map(item => (
                <LineItem
                  computing={computing}
                  key={item.description}
                  item={item}
                />))}
              {order.orderSummary && order.orderSummary.shipping &&
                <Container>
                  <Row>
                    <Col span={8} sm={6} md={6}>
                      <Text textKey="SHIPPING" textColor={COLORS.WHITE} />
                    </Col>
                    <Col span={4} sm={6} md={6}>
                      <div className={styles.rowText}>
                        {computing
                          ? <div className={`${mainStyles.animate} ${styles.bodyCopyShimmer}`} />
                          : <Text textKey={shippingAmt} disableI18n textColor={COLORS.WHITE} />}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className={styles.greyRule} />
                  </Row>
                </Container>}
              {order.orderSummary && order.orderSummary.tax &&
                <Container>
                  <Row>
                    <Col span={8} sm={6} md={6}>
                      <Text textKey="TAX" textColor={COLORS.WHITE} />
                    </Col>
                    <Col span={4} sm={6} md={6}>
                      <div className={styles.rowText}>
                        {computing
                          ? <div className={`${mainStyles.animate} ${styles.bodyCopyShimmer}`} />
                          : <Text textKey={taxAmt} disableI18n textColor={COLORS.WHITE} />}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className={styles.greyRule} />
                  </Row>
                </Container>}
              <Container>
                <Row>
                  <Col span={8} sm={6} md={6}>
                    <Text type={TextTypes.H3} textKey="TOTAL" textColor={COLORS.WHITE} />
                  </Col>
                  <Col span={4} sm={6} md={6}>
                    <div className={styles.rowText}>
                      {computing
                        ? <div className={`${mainStyles.animate} ${styles.h1Shimmer}`} />
                        : <Text
                          type={TextTypes.H3}
                          textKey={totalAmt}
                          disableI18n
                          textColor={COLORS.WHITE}
                        />}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </AccItemBody>
        </AccItem>
      </Accordion>
    </Card>);
};


export default OrderInfo;
